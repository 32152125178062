import { SVGProps } from "react"

export const AlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M14.022 6.90394H8.47068V9.2046H11.6653C11.368 10.6666 10.1233 11.5066 8.47068 11.5066C8.00823 11.5074 7.55018 11.4169 7.1228 11.2403C6.69541 11.0636 6.3071 10.8044 5.98013 10.4773C5.65317 10.1503 5.39398 9.76195 5.21743 9.33453C5.04089 8.90712 4.95046 8.44905 4.95134 7.9866C4.95055 7.52422 5.04104 7.06622 5.21763 6.63888C5.39421 6.21154 5.65341 5.82326 5.98037 5.4963C6.30733 5.16934 6.69561 4.91014 7.12295 4.73355C7.55029 4.55697 8.00829 4.46648 8.47068 4.46727C9.31001 4.46727 10.0687 4.76527 10.664 5.2526L12.3973 3.51994C11.3413 2.59927 9.98734 2.03127 8.47068 2.03127C7.68793 2.02916 6.91249 2.18178 6.18892 2.48035C5.46535 2.77891 4.80793 3.21755 4.25444 3.77104C3.70095 4.32452 3.26232 4.98195 2.96375 5.70551C2.66518 6.42908 2.51256 7.20453 2.51468 7.98727C2.5123 8.77009 2.66473 9.54565 2.96321 10.2693C3.26168 10.993 3.70031 11.6506 4.25385 12.2041C4.80739 12.7576 5.46492 13.1963 6.1886 13.4947C6.91229 13.7932 7.68786 13.9456 8.47068 13.9433C11.4487 13.9433 14.1567 11.7773 14.1567 7.98727C14.1567 7.63527 14.1027 7.25594 14.022 6.90394Z"
      fill="white"
    />
  </svg>
)
export default AlertIcon
